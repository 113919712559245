<template>
  <div class="apply-box">
    <div class="padding-box">
      <div class="table-box">
        <div class="grid-box">
          <div class="item" @click="handleScreen(i.path)" v-for="i in itemList">
            <!-- <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 40"
              data-inject-url="https://pro.solarmanpv.com/static/icons/app-display.svg"
              data-v-d7165444=""
              class="vaM tra-fast w100pct h100pct dpB"
            >
              <defs>
                <linearGradient
                  id="a--inject-6"
                  x1="-511.05"
                  y1="813.1"
                  x2="-511.05"
                  y2="812.1"
                  gradientTransform="matrix(26.88 0 0 -26.54 13754.85 21589.9)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#ffa876"></stop>
                  <stop offset="1" stop-color="#ff7052"></stop>
                </linearGradient>
                <linearGradient
                  id="b--inject-6"
                  x1="-514.12"
                  y1="754.48"
                  x2="-514.12"
                  y2="753.48"
                  gradientTransform="matrix(31.88 0 0 -3.75 16407.98 2834.31)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" stop-color="#ffbd98"></stop>
                  <stop offset="1" stop-color="#ff6e50"></stop>
                </linearGradient>
              </defs>
              <g data-name="图层 2">
                <g data-name="图层 1">
                  <g data-name="编组 8">
                    <circle cx="20" cy="20" r="20" fill="#ffe9e5"></circle>
                    <path
                      d="M26.9 34.85l-5-5.47h-3.44l-5.05 5.47a1.25 1.25 0 01-1.9-1.61l.07-.09 3.48-3.77H8.87a2 2 0 01-2-2V8.75h26.87v18.63a2 2 0 01-2 2h-6.49l3.49 3.77.07.08a1.25 1.25 0 11-1.91 1.62z"
                      fill="url(#a--inject-6)"
                    ></path>
                    <path
                      data-name="路径 12-2"
                      d="M11.87 20.11A3.33 3.33 0 0015 18c1.19-2.36 3.1-.58 4.6 1.09s2.66 4 4.84 0 2.05-5.59 4.31-5.24"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="square"
                      stroke-miterlimit="10"
                      stroke-width="2"
                    ></path>
                    <path
                      d="M6.25 5h28.13a1.88 1.88 0 011.87 1.88 1.87 1.87 0 01-1.87 1.87H6.25a1.87 1.87 0 01-1.87-1.87A1.87 1.87 0 016.25 5z"
                      fill="url(#b--inject-6)"
                    ></path>
                  </g>
                </g>
              </g>
            </svg> -->
            <img :src="i.imgsrc" alt="" />
            <div>{{ i.title }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      itemList: [
        {
          title: "大屏",
          imgsrc: require("../../assets/screen/index/1.svg"),
          path: "/apply/screenList",
        },
        {
          title: "SIM卡充值",
          imgsrc: require("../../assets/screen/index/2.svg"),
          path: "",
        },
        {
          title: "保险",
          imgsrc: require("../../assets/screen/index/3.svg"),
          path: "",
        },
        {
          title: "虚拟气象站",
          imgsrc: require("../../assets/screen/index/4.svg"),
          path: "",
        },
        {
          title: "视频监控",
          imgsrc: require("../../assets/screen/index/5.svg"),
          path: "",
        },
        {
          title: "组网",
          imgsrc: require("../../assets/screen/index/6.svg"),
          path: "",
        },
        {
          title: "IV曲线",
          imgsrc: require("../../assets/screen/index/7.svg"),
          path: "",
        },
        {
          title: "电费记录",
          imgsrc: require("../../assets/screen/index/8.svg"),
          path: "",
        },
        {
          title: "全国光伏资源区分布",
          imgsrc: require("../../assets/screen/index/9.svg"),
          path: "",
        },
        {
          title: "设备自检",
          imgsrc: require("../../assets/screen/index/10.svg"),
          path: "",
        },
        {
          title: "设备地图",
          imgsrc: require("../../assets/screen/index/11.svg"),
          path: "",
        },
      ],
    };
  },
  methods: {
    handleScreen(path) {
      // const routeUrl = this.$router.resolve({
      //   path: "/apply/screen",
      // });
      // window.open(routeUrl.href, "_blank");
      this.$router.push(path);
    },
  },
};
</script>
<style lang="less" scoped>
.apply-box {
  width: 100%;
  height: 100%;
  padding-top: 16px;
  box-sizing: border-box;
  .padding-box {
    padding: 0 4%;
    width: 100%;
    height: 100%;
    padding-top: 20px;
    display: table;
    table-layout: fixed;
    // align-items: center;
    .table-box {
      display: table-cell;
      vertical-align: middle;
      .grid-box {
        padding: 8px 0;
        width: 100%;
        // height: 100%;
        display: grid;
        // grid-template-rows: 200px 200px;
        grid-template-columns: repeat(auto-fill, 280px);
        grid-column-gap: 20px;
        // grid-row-gap: 20px;
        // align-items: center;
        justify-content: center;
        // justify-items: center;
        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          background: #fff;
          height: 240px;
          width: 280px;
          margin-bottom: 20px;
          cursor: pointer;
          transition: all 0.3s;
          img {
            width: 120px;
            height: 120px;
          }
          &:hover {
            color: #048fff;
          }
        }
      }
    }
  }
}
</style>